import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import Button, { ButtonVariants } from "../../../modules/common/components/Button/Button";
import Text from '../../../modules/common/components/Text/Text';
import { BasicPaper, HeroFilm } from "../components";
import { BasicPaperVariants } from "../components/BasicPaper";

function About() {
    const history = useHistory();
    const body = document.querySelector('#root');

    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page)
    }

    return (
        <section className="about">
            <div className="desktop">
                <Grid container spacing={10} className="container">
                    <Grid item md={6} className="about-elements">
                        <HeroFilm />
                        <div data-aos="fade-up">
                            <Text variant="h2" isBold color="bright">
                                Nasza działalność
                            </Text>
                        </div>
                        <div data-aos="fade-up">
                            <Text variant="p" color="bright">
                                Witam serdecznie. To już ponad 3 lata (październik 2019) kiedy Kroniki Akaszy otworzyły się „oficjalnie” dla mnie i mojego męża Marka podczas warsztatów u Dany. Wcześniej nieśmiało i nieco po omacku oddawaliśmy się prowadzeniu uniwersalnej energi. Jest taki dzień w życiu każdego człowieka, kiedy wie na pewno co kocha najmocniej i czego szukał tak długo… ta „meta” jest nowym „startem” w nowe życie, pełne zaufania i świadomości.
                            </Text>
                        </div>
                        <div data-aos="fade-up">
                            <Button variant={ButtonVariants.SECONDARY} onClick={() => navigateTo('/o-nas')}>
                                <Text variant="p" color="dark" isUnderline>
                                    Czytaj dalej
                                </Text>
                            </Button>
                            <Button variant={ButtonVariants.SECONDARY} onClick={() => navigateTo('/filmy')}>
                                <Text variant="p" color="dark" isUnderline>
                                    Filmy
                                </Text>
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={6} className="about-elements">
                        <Grid container spacing={3} className="w-full">
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Kontakt" description="95% “odczytów” robię telefonicznie. Proszę o imiona, nazwiska oraz datę urodzenia." />
                            </Grid>
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Zaufanie" description="Dane dotyczące Ciebie są wiadome tylko mi i tylko na potrzeby “odczytu”. Nie przechowuję żadnych notatek. Wszystko po “odczycie” jest niszczone." />
                            </Grid>
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Filozofia" description="Mając dane osobowe pytam w swoich Kronikach Akaszy, czy mogę zrobić “odczyt” oraz dowiaduję się jaka jest jego wycena. Przesyłam Ci informację zwrotną. Jeśli ją zaakceptujesz umawiamy wolny termin." />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className="mobile">
                <Grid container spacing={10} className="container">
                    <Grid item lg={6} md={12} className="about-elements">
                        <Grid container spacing={3} className="w-full">
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Kontakt" description="95% “odczytów” robię telefonicznie. Proszę o imiona, nazwiska oraz datę urodzenia." />
                            </Grid>
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Zaufanie" description="Dane dotyczące Ciebie są wiadome tylko mi i tylko na potrzeby “odczytu”. Nie przechowuję żadnych notatek. Wszystko po “odczycie” jest niszczone." />
                            </Grid>
                            <Grid item className="w-full">
                                <BasicPaper variant={BasicPaperVariants.ABOUT} title="Filozofia" description="Mając dane osobowe pytam w swoich Kronikach Akaszy, czy mogę zrobić “odczyt” oraz dowiaduję się jaka jest jego wycena. Przesyłam Ci informację zwrotną. Jeśli ją zaakceptujesz umawiamy wolny termin." />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} className="">
                        <HeroFilm />
                        <div data-aos="fade-up">
                            <Text variant="h2" isBold color="bright">
                                Nasza działalność
                            </Text>
                        </div>
                        <div data-aos="fade-up">
                            <Text variant="p" color="bright">
                                Witam serdecznie. To już ponad 3 lata (październik 2019) kiedy Kroniki Akaszy otworzyły się „oficjalnie” dla mnie i mojego męża Marka podczas warsztatów u Dany. Wcześniej nieśmiało i nieco po omacku oddawaliśmy się prowadzeniu uniwersalnej energi. Jest taki dzień w życiu każdego człowieka, kiedy wie na pewno co kocha najmocniej i czego szukał tak długo… ta „meta” jest nowym „startem” w nowe życie, pełne zaufania i świadomości.
                            </Text>
                        </div>
                        <div data-aos="fade-up">
                            <Button variant={ButtonVariants.SECONDARY} onClick={() => navigateTo('/o-nas')}>
                                <Text variant="p" color="dark" isUnderline>
                                    Czytaj dalej
                                </Text>
                            </Button>
                            <Button variant={ButtonVariants.SECONDARY} onClick={() => navigateTo('/filmy')}>
                                <Text variant="p" color="dark" isUnderline>
                                    Filmy
                                </Text>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

export default About;
