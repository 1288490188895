import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../modules/common/components";
import { ButtonVariants } from "../../modules/common/components/Button/Button";
import { BasicPaper } from "../main/components";
import { BasicPaperVariants } from "../main/components/BasicPaper";

type TFilms = {
    title: string;
    url: string;
}

function FilmsPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;Filmy
            </>
        )
    }, []);

    const films: TFilms[] = [
        {
            title: "Warsztaty , Kroniki Akaszy i ustawienia 'Hellingerowskie' Wdzięczni za wspólny czas.",
            url: "https://www.youtube.com/embed/4iUudckGRCk?si=iiHRIo0n6FM0Y_f4"
        },
        {
            title: "Kroniki Akaszy & Ustawienia Systemowe",
            url: "https://www.youtube.com/embed/EI4JQrOj90A?si=YlYPqKOPNQeqYi1P"
        },
        {
            title: "Oczyszczenie z negatywnych energii. Domy, mieszkania, firmy",
            url: "https://www.youtube.com/embed/KEfg3WFtoRI?si=b0XcyoaVhBBqqELm"
        },
        {
            title: "Reinkarnacja w Kronikach Akaszy",
            url: "https://www.youtube.com/embed/mSMuMSsdh2w?si=V8KkH1zN8Lj3UYEO"
        },
        {
            title: "Ustawienia systemowe.Trójkąt zwycięzcy .Część 2.",
            url: "https://www.youtube.com/embed/9e5V8JQidwQ?si=i54nie2D_39KOjIa"
        },
        {
            title: "Ustawienia systemowe,a trójkąt dramatyczny.Część 1.",
            url: "https://www.youtube.com/embed/f3I0M4u1lcE?si=2TcYDOHrVPQKfmAW"
        },
        {
            title: "Wspomnienie. Noc Kupały 2023.",
            url: "https://www.youtube.com/embed/GIEgo2OGHLQ?si=UQduzsDBJ-NzJB4z"
        },
        {
            title: "Technika, jak podnieść sobie poziom energetyczny ?",
            url: "https://www.youtube.com/embed/68V954U56r4"
        },
        {
            title: "Ustawienia systemowe Berta Hellingera,family coach.",
            url: "https://www.youtube.com/embed/tuPuFbCT4V8"
        },
        {
            title: `Przekaz z Kronik Akaszy "komu ufać"?`,
            url: "https://www.youtube.com/embed/InB_mH89RFw"
        },
        {
            title: "Ja i mój (Twój) cień, podsumowanie",
            url: "https://www.youtube.com/embed/Izo6MdMySXo"
        },
        {
            title: "Ja i mój ( Twój ) cień , część 4.",
            url: "https://www.youtube.com/embed/3bOkY61DyLI"
        },
        {
            title: "Ja i mój ( Twój ) cień , część 3.",
            url: "https://www.youtube.com/embed/5oM6ZWcptZg"
        },
        {
            title: "Ja i mój ( Twój ) cień , część 2.",
            url: "https://www.youtube.com/embed/r6xzm2xdjQI"
        },
        {
            title: "Ja i mój ( Twój ) cień , część 1.",
            url: "https://www.youtube.com/embed/MQ20eddyCiI"
        },
        {
            title: "Żyj w światłości",
            url: "https://www.youtube.com/embed/iM0Agu04gUo"
        },
        {
            title: "Kroniki Akaszy - duchowość a świadomość",
            url: "https://www.youtube.com/embed/of6wbGnZHco"
        },
        {
            title: "Kroniki Akaszy - co z tą klątwą?",
            url: "https://www.youtube.com/embed/dB4co6bR5Bc"
        },
        {
            title: "Kroniki Akaszy - skąd energia, aby żyć?",
            url: "https://www.youtube.com/embed/LRKdeGwpz2g"
        },
        {
            title: "Kroniki Akaszy - przekaz - aktualna sytuacja na świecie",
            url: "https://www.youtube.com/embed/X6OaeVwg5hI"
        },
        {
            title: "Przekaz noworoczny 2021/2022 Danka i Marek Żukowscy",
            url: "https://www.youtube.com/embed/HRE3FMtHWmM"
        },
        {
            title: "KRONIKI AKASZY - ODPOWIEDZI NA TWOJE PYTANIA - Danka i Marek Żukowscy spotkanie NA ŻYWO",
            url: "https://www.youtube.com/embed/Y-17CWxdDT4"
        },
        {
            title: "WYJĄTK0WE SP0TKANIE Z KR0NIKAMI AKASZY, TAJEMNICZY MAGICZNY KRĄG - Danka I Marek Żukowscy @2021 VTV",
            url: "https://www.youtube.com/embed/Ykv6TDV3fyc"
        }
    ];

    return (
        <Page title={title} isContactFormHidden>
            <Grid container spacing={3}>
                {films?.map((film) => {
                    return (
                        <Grid item lg={3} md={4} xs={12}>
                            <BasicPaper
                                variant={BasicPaperVariants.FILMS}
                                buttonVariant={ButtonVariants.PRIMARY}
                                title={film.title}
                                filmUrl={film.url}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Page>
    )
}

export default FilmsPage;