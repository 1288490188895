import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../../modules/common/components/Text/Text";
import Socialmedia from "../socialmedia/Socialmedia";

function Footer() {
  const body = document.querySelector("#root");
  const gotoUp = () => {
    body?.scrollIntoView();
  };

  return (
    <section className="footer" id="stopka">
      <div className="container py-5" data-aos="fade-up">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <img
              src="/assets/images/logo-blue.webp"
              alt="Główna logo strony"
              width="70px"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Text variant="h4" isBold color="dark">
              Oferta
            </Text>
            <Text variant="p" color="dark">
              <Link to="/oferta/kroniki-akaszy" onClick={() => gotoUp()}>
                Czytanie z Kronik Akaszy
              </Link>
              <br />
              <Link to="/oferta/bioenergoterapia" onClick={() => gotoUp()}>
                Bioenergoterapia
              </Link>
              <br />
              <Link to="/oferta/radiestezja" onClick={() => gotoUp()}>
                Radiestezja
              </Link>
              <br />
              <Link to="/oferta/decoding-oddechowy" onClick={() => gotoUp()}>
                Decoding oddechowy
              </Link>
              <br />
              <Link to="/oferta/ustawienia-systemowe" onClick={() => gotoUp()}>
                Ustawienia systemowe
              </Link>
              <br />
              <Link to="/offer/regresja-hipnotyczna" onClick={() => gotoUp()}>
                Regresja hipnotyczna
              </Link>
              <br />
            </Text>
          </Grid>
          <Grid item md={3} xs={12}>
            <Text variant="h4" isBold color="dark">
              Warsztaty
            </Text>
            <Text variant="p" color="dark">
              <Link
                to="/warsztat/be86dee5-fd34-4e0d-beec-5ba012941e69"
                onClick={() => gotoUp()}
              >
                "Otwieranie i czytanie z Kronik Akaszy"
              </Link>
              <br />
              <Link
                to="/warsztat/dd1ea1d7-1ffe-4a37-954a-db7c053d6acc"
                onClick={() => gotoUp()}
              >
                "Ustawienia systemowe"
              </Link>
              <br />
              <Link
                to="/warsztat/b4b2c79b-e282-40ac-9524-80bcc9e4e5b6"
                onClick={() => gotoUp()}
              >
                "Chcesz mieć rację czy relację?"
              </Link>
            </Text>
          </Grid>
          <Grid item md={3} xs={12}>
            <Text variant="h4" isBold color="dark">
              Materiały
            </Text>
            <Text variant="p" color="dark">
              <Link
                to="/materialy/7-praw-wszechswiata"
                onClick={() => gotoUp()}
              >
                7 praw wszechświata
              </Link>
              <br />
              <Link
                to="/materialy/cwiczenia-oddechowe"
                onClick={() => gotoUp()}
              >
                Świadomy oddech
              </Link>
              <br />
              <Link
                to="/materialy/odczyty-niespersonalizowane"
                onClick={() => gotoUp()}
              >
                Odczyty niespersonalizowane
              </Link>
              <br />
              <Link
                to="/materialy/przykladowe-ustawienia-systemowe"
                onClick={() => gotoUp()}
              >
                Przykładowe ustawienia systemowe
              </Link>
              <br />
            </Text>
          </Grid>
          <Grid item md={3} xs={12}>
            <Text variant="h4" isBold color="dark">
              Kontakt
            </Text>
            <Text variant="p" color="dark">
              <a href="mail:kontakt@kroniki-akaszy.info">
                <span>kontakt@kroniki-akaszy.info</span>
              </a>
              <div className="phone d-flex">
                <span>Marek</span>
                <a href="tel:+48505072849">
                  <span>+48 505 072 849</span>
                </a>
              </div>
              <div className="phone d-flex">
                <span>Danka</span>
                <a href="tel:+48500193207">
                  <span>+48 500 193 207</span>
                </a>
              </div>
            </Text>
            <Socialmedia isDark />
          </Grid>
        </Grid>
      </div>
      <div className="container py-1 bg-white">
        <Grid container>
          <Grid item md={12}>
            <Text variant="p" color="dark">
              © 2020 - 2025 kroniki-akaszy.info | Wszelkie prawa zastrzeżone |
              Pomoc techniczna: marcin@rybkowski.pl
            </Text>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Footer;
