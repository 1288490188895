import MainPageConfig from "./main/main.config";
import AboutPageConfig from "./about/about.config";
import FilmsPageConfig from "./films/films.config";
import {
  AkashaPageConfig,
  BioenergotherapyConfig,
  BreathingExercisesConfig,
  DecodingConfig,
  DowsingConfig,
  EventsConfig,
  EventsDetailsConfig,
  LawsOfTheUniverseConfig,
  NonPersonalizedReadingConfig,
  SystemConfig,
  SystemSettingExamplesPageConfig,
  ContactConfig,
  OfferConfig,
  MaterialsConfig,
  WorkshopsConfig,
  RegressionConfig,
} from ".";

const pagesConfig = [
  MainPageConfig,
  AboutPageConfig,
  SystemConfig,
  FilmsPageConfig,
  AkashaPageConfig,
  BioenergotherapyConfig,
  DowsingConfig,
  DecodingConfig,
  EventsConfig,
  EventsDetailsConfig,
  BreathingExercisesConfig,
  LawsOfTheUniverseConfig,
  NonPersonalizedReadingConfig,
  SystemSettingExamplesPageConfig,
  ContactConfig,
  OfferConfig,
  MaterialsConfig,
  WorkshopsConfig,
  RegressionConfig,
];

export default pagesConfig;
