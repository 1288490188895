import { Button, Fade, Menu, MenuItem } from "@mui/material";
import { useCallback, useState } from "react";
import "./Navbar.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Text from "../../../modules/common/components/Text/Text";
import { Link, useHistory } from "react-router-dom";
import { HideScroll } from "react-hide-on-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import Socialmedia from "../socialmedia/Socialmedia";
import CloseIcon from "@mui/icons-material/Close";

function Navbar() {
  const history = useHistory();
  const body = document.querySelector("#root");
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement | any>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement | any>(null);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement | any>(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const handleOfferClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleWorkshopsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMaterialsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const navigateTo = (page: string) => {
    body?.scrollIntoView();
    history.push(page);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const isPageActive = useCallback(
    (pagePathname: string) => {
      if (history.location.pathname === pagePathname) {
        return true;
      }
      return false;
    },
    [history]
  );

  const Logo = () => {
    return (
      <Link to="/" onClick={() => close()}>
        <img
          src="/assets/images/logo.webp"
          alt="Główne logo strony"
          className="nav-logo"
        />
      </Link>
    );
  };

  const [isMenuOpened, setOpenMenu] = useState<boolean>(false);

  const close = () => {
    body?.scrollIntoView();
    setOpenMenu(false);
  };

  return (
    <>
      <HideScroll variant="down">
        <nav data-aos="fade-up">
          <div className="desktop w-full">
            <div className="d-flex w-full align-items-center justify-space-between">
              <div className="d-flex align-items-center">
                <Logo />
                <Button
                  id="fade-button"
                  aria-haspopup="true"
                  onClick={() => navigateTo("/")}
                >
                  <Text variant="h5" color="bright" isBold={isPageActive("/")}>
                    Strona główna
                  </Text>
                </Button>
                <Button
                  id="fade-button"
                  aria-haspopup="true"
                  onClick={() => navigateTo("/o-nas")}
                >
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/o-nas")}
                  >
                    O nas
                  </Text>
                </Button>
                <Button
                  id="offer-button"
                  aria-controls={open1 ? "offer-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleOfferClick}
                  color="primary"
                >
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/oferta")}
                  >
                    Oferta
                  </Text>
                </Button>
                <Menu
                  id="offer-menu"
                  MenuListProps={{
                    "aria-labelledby": "offer-button",
                  }}
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() => navigateTo("/oferta/kroniki-akaszy")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/oferta/kroniki-akaszy")}
                      isPadding={false}
                    >
                      Czytanie z Kronik Akaszy
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/oferta/bioenergoterapia")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/oferta/bioenergoterapia")}
                      isPadding={false}
                    >
                      Bioenergoterapia
                    </Text>
                  </MenuItem>
                  <MenuItem onClick={() => navigateTo("/oferta/radiestezja")}>
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/oferta/radiestezja")}
                      isPadding={false}
                    >
                      Radiestezja
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/oferta/decoding-oddechowy")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/oferta/decoding-oddechowy")}
                      isPadding={false}
                    >
                      Decoding oddechowy
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/oferta/ustawienia-systemowe")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/oferta/ustawienia-systemowe")}
                      isPadding={false}
                    >
                      Ustawienia systemowe
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/offer/regresja-hipnotyczna")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/offer/regresja-hipnotyczna")}
                      isPadding={false}
                    >
                      Regresja hipnotyczna
                    </Text>
                  </MenuItem>
                </Menu>
                <Button
                  id="offer-button"
                  aria-controls={open2 ? "offer-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleWorkshopsClick}
                  color="primary"
                >
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/warsztaty")}
                  >
                    Warsztaty
                  </Text>
                </Button>
                <Menu
                  id="offer-menu"
                  MenuListProps={{
                    "aria-labelledby": "offer-button",
                  }}
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() =>
                      navigateTo(
                        "/warsztat/be86dee5-fd34-4e0d-beec-5ba012941e69"
                      )
                    }
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive(
                        "/warsztat/be86dee5-fd34-4e0d-beec-5ba012941e69"
                      )}
                      isPadding={false}
                    >
                      Otwieranie i czytanie z Kronik Akaszy
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigateTo(
                        "/warsztat/b4b2c79b-e282-40ac-9524-80bcc9e4e5b6"
                      )
                    }
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive(
                        "/warsztat/b4b2c79b-e282-40ac-9524-80bcc9e4e5b6"
                      )}
                      isPadding={false}
                    >
                      Chcesz mieć rację czy relację?
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigateTo(
                        "/warsztat/dd1ea1d7-1ffe-4a37-954a-db7c053d6acc"
                      )
                    }
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive(
                        "/warsztat/dd1ea1d7-1ffe-4a37-954a-db7c053d6acc"
                      )}
                      isPadding={false}
                    >
                      Ustawienia systemowe
                    </Text>
                  </MenuItem>
                </Menu>
                <Button
                  id="materials-button"
                  aria-controls={open3 ? "materials-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? "true" : undefined}
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleMaterialsClick}
                  color="primary"
                >
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/materialy")}
                  >
                    Materiały
                  </Text>
                </Button>
                <Menu
                  id="materials-menu"
                  MenuListProps={{
                    "aria-labelledby": "materials-button",
                  }}
                  anchorEl={anchorEl3}
                  open={open3}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() =>
                      navigateTo("/materialy/odczyty-niespersonalizowane")
                    }
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive(
                        "/materialy/odczyty-niespersonalizowane"
                      )}
                      isPadding={false}
                    >
                      Odczyty niespersonalizowane
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/materialy/7-praw-wszechswiata")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/materialy/7-praw-wszechswiata")}
                      isPadding={false}
                    >
                      7 Uniwersalnych Praw Wszechświata
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigateTo("/materialy/cwiczenia-oddechowe")}
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive("/materialy/cwiczenia-oddechowe")}
                      isPadding={false}
                    >
                      Świadomy oddech
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigateTo("/materialy/przykladowe-ustawienia-systemowe")
                    }
                  >
                    <Text
                      variant="p"
                      color="dark"
                      isBold={isPageActive(
                        "/materialy/przykladowe-ustawienia-systemowe"
                      )}
                      isPadding={false}
                    >
                      Przykładowe Ustawienia Systemowe
                    </Text>
                  </MenuItem>
                </Menu>
                <Button
                  id="fade-button"
                  aria-haspopup="true"
                  onClick={() => navigateTo("/filmy")}
                >
                  {/* <a href="https://www.youtube.com/channel/UCvxMtrBMz4qt_FeQpGlk7_g"> */}
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/filmy")}
                  >
                    Filmy
                  </Text>
                  {/* </a> */}
                </Button>
                <Button
                  id="fade-button"
                  aria-haspopup="true"
                  onClick={() => navigateTo("/kontakt")}
                >
                  <Text
                    variant="h5"
                    color="bright"
                    isBold={isPageActive("/kontakt")}
                  >
                    Kontakt
                  </Text>
                </Button>
              </div>
              <div>
                <Socialmedia />
              </div>
            </div>
          </div>
          {!isMenuOpened ? (
            <div className="mobile w-full">
              <div className="d-flex w-full justify-space-between align-items-center">
                <Logo />
                <Button onClick={() => setOpenMenu(true)} className="reset">
                  <MenuIcon
                    fontSize="large"
                    color="primary"
                    className="white-font"
                  />
                </Button>
              </div>
            </div>
          ) : null}
        </nav>
      </HideScroll>
      {isMenuOpened ? (
        <div
          data-aos="flip-left"
          className="mobile menu absolute w-full h-full"
        >
          <div className="d-flex w-full justify-space-between align-items-center">
            <Logo />
            <Button onClick={() => setOpenMenu(false)} className="reset">
              <CloseIcon
                fontSize="large"
                color="primary"
                className="white-font"
              />
            </Button>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column h-full">
            <ul>
              <li>
                <Link to="/" onClick={() => close()}>
                  Strona główna
                </Link>
              </li>
              <li>
                <Link to="/o-nas" onClick={() => close()}>
                  O nas
                </Link>
              </li>
              <li>
                <Link to="/oferta" onClick={() => close()}>
                  Oferta
                </Link>
              </li>
              {/* <ol>
                                <li>Czytanie z Kronik Akaszy</li>
                                <li>Bioenergoterapia</li>
                                <li>Radiestezja</li>
                                <li>Decoding oddechowy</li>
                                <li>Ustawienia systemowe</li>
                            </ol> */}
              <li>
                <Link to="/warsztaty" onClick={() => close()}>
                  Warsztaty
                </Link>
              </li>
              {/* <ol>
                                <li>Otwieranie i czytanie z Kronik Akaszy</li>
                                <li>Chcesz mieć rację czy relację?</li>
                                <li>Ustawienia systemowe</li>
                            </ol> */}
              <li>
                <Link to="/materialy" onClick={() => close()}>
                  Materiały
                </Link>
              </li>
              {/* <ol>
                                <li>Odczyty niespersonalizowane</li>
                                <li>7 Uniwersalnych Praw Wszechświata</li>
                                <li>Świadomy oddech</li>
                                <li>Przykładowe ustawienia systemowe</li>
                            </ol> */}
              <li>
                <Link to="/filmy" onClick={() => close()}>
                  Filmy
                </Link>
              </li>
              <li>
                <Link to="/kontakt" onClick={() => close()}>
                  Kontakt
                </Link>
              </li>
              <li>
                <Socialmedia />
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Navbar;
