import axios from "axios";

// const API_URL = "https://api.preprod.kroniki-akaszy.info";
const API_URL = "https://api.kroniki-akaszy.info";

const API = {
  getWorkshopList: () => axios.get(`${API_URL}/workshops`),
  getEvents: (id: string) => axios.get(`${API_URL}/workshops/${id}/events`),
  getEvent: (id: string) => axios.get(`${API_URL}/events/${id}`),
  contactForm: {
    postMessage: (formValues: any) => {
      return axios.post(`${API_URL}/notifications/contactForm`, formValues);
    },
  },
};

export default API;
